<template>
  <div class="h-screen">
    <div
      class="container h-full px-4 mx-auto"
    >
      <router-view />
    </div>
  </div>
</template>

<script>
export default {

}
</script>
